<template>
  <v-row>
    <v-col cols="12" sm="12">
      <v-row>
        <v-col cols="12" xs="5" md="5">
          <h1 class="primary--text">Members</h1>
        </v-col>

        <v-col cols="6" xs="2" md="2" align-self="center">
          <v-dialog
            v-if="is_admin"
            v-model="invitationModal"
            persistent
            max-width="700px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                class="primary rounded-pill px-5"
                v-bind="attrs"
                v-on="on">
                Invite
              </v-btn>
            </template>
            <add-member
              :is_admin="is_admin"
              :role_list="role_list"
              @reloadList="getAllMembersFunction()"
              @closeModal="invitationModal=false">
            </add-member>
          </v-dialog>
        </v-col>

        <v-col cols="6" xs="5" md="5">
          <v-text-field
            v-model="search"
            dense
            outlined
            :prepend-inner-icon="mdiMagnify"
            label="Search My History.."
            class="float-right"
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      
      <v-row class="mt-0">
        <v-col cols="12" xs="12" md="12">
          <v-card>
            <v-card-text class="px-0 pt-0">
              <v-skeleton-loader
                v-if="isLoading"
                type="table"
                class="mr-2 mt-6"
              >
              </v-skeleton-loader>


              <!-- table data here  -->
              <v-data-table
                v-else
                :headers="headers"
                :items="allMembers.items"
                :sort-by="['updated_at']"
                :sort-desc="[true]"
                :search="search"
                :page.sync="page"
                :items-per-page="itemsPerPage"
                hide-default-footer
                @page-count="pageCount = $event"
              >
                <template v-slot:[`item.full_name`]="{ item }">
                  <span>{{item.first_name}} {{item.last_name}}</span>
                </template>

                <template v-slot:[`item.email`]="{ item }" class="d-inline-inherit">
                  <span v-if="!item.email_verified">{{ item.email }}</span>
                  <v-badge v-else color="green" dot>{{ item.email }}</v-badge>
                </template>

                <template v-slot:[`item.updated_at`]="{ item }">
                  {{ $tools.dateTimeToStr(item.updated_at, "yyyy-mm-dd") }}
                </template>

                <template v-slot:[`item.role`]="{ item }">
                  <v-btn
                    v-if="is_admin"
                    small
                    class="info rounded-pill"
                    @click="showAccessModal(item)">
                    Set Access
                  </v-btn>
                </template>

                <template v-slot:[`item.status`]="{ item }">
                  <v-chip
                    v-if="!is_admin || (is_admin && loggedin_user==item.id)"
                    small
                    outlined
                    class="text-capitalize"
                    :color="getColorOfStatus(item.status)"
                  >
                    {{ item.status=='created' ? "Invited" : item.status }}
                  </v-chip>
                  <v-select
                    v-else
                    class="custom-width"
                    :items="(item.status=='created' ? [] : status_list)"
                    item-text="text"
                    item-value="value"
                    v-model="item.status"
                    :placeholder="(item.status=='created' ? 'Invited' : item.status)"
                    dense
                    outlined
                    v-on:change="changeEvent('status', item, $event)"
                  ></v-select>
                </template>
              </v-data-table>
            </v-card-text>

            <v-card-actions class="px-0">
              <div v-if="!rows"></div>
              <v-row v-else>
                <v-col class="py-0" cols="12" md="12">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" xs="12" sm="6" md="6" offset-md="3">
                  <v-pagination
                    class="text-center"
                    v-model="page"
                    :length="pageCount"
                  ></v-pagination>
                </v-col>
                <v-col cols="12" offset-sm="1" offset-md="1" xs="3" sm="2" md="2">
                  <v-select
                    v-model="itemsPerPage"
                    :items="rows"
                    hide-details="auto"
                    class="px-3"
                    outlined
                    dense
                  ></v-select>
                </v-col>
              </v-row>
            </v-card-actions>

          </v-card>
        </v-col>
      </v-row>
    </v-col>

    <v-dialog
      v-model="setAccessModal"
      persistent
      max-width="700px">
      <set-access
        :role_list="role_list"
        :userData="memberData"
        @reloadList="getAllMembersFunction()"
        @closeAccessModal="setAccessModal=false">
      </set-access>
    </v-dialog>

    <DialogModal
      v-model="confirm_dialog"
      title="Confirm"
      :message="dialog_msg"
      @onCancel="cancelUpdate()"
      @onConfirmation="onUpdateConfirmed()"
    />

  </v-row>
</template>


<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { mdiMagnify } from '@mdi/js'

import {
  LOAD_ALL_MEMBERS, REFRESHTOKEN, UPDATE_MEMBER
} from "@/store/_actiontypes";
import DialogModal from "@/components/common/DialogModal";
import AddMember from "./AddMember";
import SetAccess from "./SetAccess";
import { Role } from "@/utils/constant";


export default {
  components: {
    DialogModal,
    "add-member": AddMember,
    "set-access": SetAccess,
  },

  props: {
    status_list: {
      type: Array,
      default(){
        return [
          {
            text: "Active",
            value: "active",
          },
          {
            text: "Inactive",
            value: "inactive",
          }
        ];
      }
    },
    headers: {
      type: Array,
      default(){
        return [
          {
            text: "Name",
            value: "full_name",
            sortable: true,
            align: "start",
          },
          {
            text: "Email",
            value: "email",
            sortable: true,
            align: "start",
          },
          {
            text: "Last Updated",
            align: "start",
            sortable: true,
            value: "updated_at",
          },
          {
            text: "Access",
            align: "start",
            sortable: true,
            value: "role",
          },
          {
            text: "Status",
            align: "start",
            sortable: true,
            value: "status",
          },
        ]
      }
    }
  },

  computed: {
    ...mapState({
      allMembers: (state) => state.member.allMembers,
      user: (state) => state.account.user,
    }),
    ...mapGetters("loader", ["loading"]),
  },

  mounted(){
    this.getAllMembers();
  },

  data(){
    let role_list = []
    Object.keys(Role).forEach(key => {
      if(!["super","client"].includes(Role[key])){
        role_list.push({
          text: Role[key].toUpperCase(),
          value: Role[key]
        });
      }
    })

    return {
      mdiMagnify,
      setAccessModal: false,
      memberData: {},
      invitationModal: false,
      role_list: role_list,
      is_admin: this.$tools.authorize([Role.SUPER]),
      loggedin_user: '',
      confirm_dialog: false,
      change_action: '',
      change_request_id: '',
      change_event_val: '',
      dialog_msg: '',
      isLoading: false,
      detailsDialog: false,
      search: '',
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      rows: [ 10, 10, 20 ],
    };
  },

  methods: {
    ...mapActions("member", [LOAD_ALL_MEMBERS, UPDATE_MEMBER]),
    ...mapActions("account", [REFRESHTOKEN]),

    getAllMembers(){
      this.isLoading = true;
      if (this.$tools.timeToNextToken() < 300){
        this.REFRESHTOKEN({
          refresh_token: this.user.refresh_token,
        }).then(
          (response) => {
            this.getAllMembersFunction();
          },
          (error) => error
        );
      } else {
        this.getAllMembersFunction();
      }
    },
    getAllMembersFunction(){
      this.loggedin_user = this.loggedinUserId()

      this.LOAD_ALL_MEMBERS().then(
        (response) => {
          this.isLoading = false;
          this.invitationModal = false;
          this.setAccessModal = false;
        },
        (error) => {
          this.isLoading = false;
          this.invitationModal = false;
          this.setAccessModal = false;
        }
      );
    },
    cancelUpdate(){
      this.confirm_dialog = false;
      this.getAllMembersFunction();
    },
    changeEvent(action, item, event){
      let dialog_msg = "Are you sure to update role for __email__?";
      if(action == "status"){
        dialog_msg = "Are you sure to update status for __email__?";
      }
      this.dialog_msg = dialog_msg.replace("__email__", item.email);
      this.confirm_dialog = true;
      this.change_action = action;
      this.change_request_id = item.id;
      this.change_event_val = event;
    },
    onUpdateConfirmed(){
      this.confirm_dialog = false;
      this.isLoading = true;

      this.UPDATE_MEMBER({
        member_id: this.change_request_id,
        action: this.change_action,
        value: this.change_event_val
      }).then((res) => {
        this.isLoading = false;
        this.getAllMembersFunction();
      }).catch((err) => {
        this.isLoading = false;
      });
    },
    showAccessModal(data){
      this.memberData = data;
      this.setAccessModal = true;
    },
    getColorOfStatus(status){
      let color = "red";
      if(status==='verified' || status==='active'){
        color = "green";
      } else if(status==='pending' || status==='created'){
        color = "orange";
      }
      return color;
    },
    loggedinUserId(){
      let user_data = this.$tools.parseJwt(this.user.access_token)
      return user_data.sub
    }
  },
};
</script>

<style>
.custom-width{
  width: 115px;
  margin-bottom: -26px !important;
}
</style>
