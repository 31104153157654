<template>
  <div>
    <v-card>
      <v-card-title class="headline primary white--text">
        Update Member Access
      </v-card-title>

      <v-card-text>
        <v-container class="px-0 pb-0 pr-3">
          <v-form ref="AccessForm" @submit.prevent="updateAccess()">
            <v-row>
              <v-col>
                <h3 class="mb-2 primary--text">
                  {{userData.first_name}} {{userData.last_name}}
                </h3>
                <p><b>Email: </b>{{userData.email}}</p>
              </v-col>
            </v-row>

            <v-row class="mb-3">
              <v-col v-for="item in role_list" :key="item.value"
                cols="12"
                sm="4"
                md="4"
              >
                <v-checkbox
                  v-model="userData.roles"
                  ref="roles"
                  :label="item.text"
                  :value="item.value"
                  color="primary"
                  hide-details
                ></v-checkbox>
              </v-col>
            </v-row>

          </v-form>
        </v-container>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          small
          text
          type="reset"
          class="error--text rounded-pill px-5 font-weight-bold"
          @click.stop="cancelSetAccess"
        >
          Cancel
        </v-btn>
        <v-btn
          small
          class="primary rounded-pill px-5 font-weight-bold"
          :loading="enable_btn_loading"
          :disabled="(userData.roles.length>0) ? false : true"
          @click.stop="updateAccess"
        >
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { UPDATE_MEMBER } from "@/store/_actiontypes";


export default {
  props: {
    role_list: {
      type: Array,
      default: [],
    },
    userData: {
      type: Object,
      default: {}
    }
  },
  data(){
    return {
      setAccessModal: false,
      enable_btn_loading: false,
      disable_submit_btn: true,
    };
  },

  methods: {
    ...mapActions("member", [UPDATE_MEMBER]),

    updateAccess(){
      if(this.userData.length<0) return;

      if(this.userData.roles){
        this.enable_btn_loading = true;
        this.disable_submit_btn = true;

        this.UPDATE_MEMBER({
          member_id: this.userData.id,
          action: "role",
          value: this.userData.roles
         }).then((res) => {
          this.enable_btn_loading = false;
          this.disable_submit_btn = false;
          this.setAccessModal = false;
          this.$emit("reloadList", true);
        }).catch((err) => {
          this.enable_btn_loading = false;
          this.disable_submit_btn = false;
        });
      }
    },
    cancelSetAccess(){
      this.setAccessModal = false;
      this.enable_btn_loading = false;
      this.disable_submit_btn = false;
      this.$emit("closeAccessModal", false);
    },
  }
};
</script>