<template>
  <div>
    <v-card>
      <v-card-title class="headline primary white--text">
        Invite New Member
      </v-card-title>

      <v-card-text>
        <v-container class="px-0 pb-0 pr-3">
          <v-form ref="invitationForm" @submit.prevent="sendInvitation()">
            <v-row class="mt-0">
              <v-col cols="4" sm="4">
                <v-subheader>Email</v-subheader>
              </v-col>
              <v-col cols="8" sm="8">
                <v-text-field
                  v-model="invitationForm.email"
                  type="email"
                  placeholder="adam@doers.com"
                  :rules="[required('Email'), email('Email')]"
                  autocomplete="off"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row class="mt-0">
              <v-col cols="4" sm="4">
                <v-subheader>Role</v-subheader>
              </v-col>
              <v-col cols="8" sm="8">
                <form autocomplete="off" @submit.prevent>
                  <v-autocomplete
                    :items="role_list"
                    item-text="text"
                    item-value="value"
                    placeholder="Choose One"
                    v-model="invitationForm.role"
                    :rules="[required('Role')]"
                    dense
                  ></v-autocomplete>
                </form>
              </v-col>
            </v-row>
            
            <v-row class="mt-0">
              <v-col cols="4" sm="4">
                <v-subheader>First Name</v-subheader>
              </v-col>
              <v-col cols="8" sm="8">
                <v-text-field
                  placeholder="John"
                  v-model="invitationForm.first_name"
                  autocomplete="off"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row class="mt-0">
              <v-col cols="4" sm="4">
                <v-subheader>Last Name</v-subheader>
              </v-col>
              <v-col cols="8" sm="8">
                <v-text-field
                  placeholder="Doe"
                  v-model="invitationForm.last_name"
                  autocomplete="off"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          small
          text
          type="reset"
          class="error--text rounded-pill px-5 font-weight-bold"
          @click.prevent="cancelInvitation"
        >
          Cancel
        </v-btn>
        <v-btn
          small
          class="primary rounded-pill px-5 font-weight-bold"
          :loading="enable_btn_loading"
          :disabled="(invitationForm.email && invitationForm.role) ? false : true"
          @click="sendInvitation"
        >
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { SEND_MEMBER_INVITATION } from "@/store/_actiontypes";
import validations from "@/utils/validations";


export default {
  props: {
    role_list: {
      type: Array,
      default: [],
    },
    is_admin: {
      type: Boolean,
      default: false,
    }
  },
  data(){
    return {
      ...validations,
      invitationModal: false,
      enable_btn_loading: false,
      disable_submit_btn: true,
      invitationForm: {
        email: "",
        role: "",
        first_name: "",
        last_name: "",
      },
    };
  },

  methods: {
    ...mapActions("member", [SEND_MEMBER_INVITATION]),

    sendInvitation(){
      if(!this.$refs.invitationForm.validate()) return;

      const {email,role,first_name,last_name} = this.invitationForm;
      if(email && role){
        this.enable_btn_loading = true;
        this.disable_submit_btn = true;

        this.SEND_MEMBER_INVITATION({
          email: email,
          role: role,
          first_name: first_name,
          last_name: last_name
         }).then((res) => {
          this.enable_btn_loading = false;
          this.disable_submit_btn = false;
          this.invitationModal = false;

          this.$refs.invitationForm.reset();
          this.$emit("reloadList", true);
        }).catch((err) => {
          this.enable_btn_loading = false;
          this.disable_submit_btn = false;
        });
      }
    },
    cancelInvitation(){
      this.invitationModal = false;
      this.enable_btn_loading = false;
      this.disable_submit_btn = false;
      this.$refs.invitationForm.reset();
      this.$emit("closeModal", false);
    },
  }
};
</script>